import React from 'react';

import Layout from '../components/organisms/Layout/Layout';
import PageWidth from '../components/organisms/PageWidth/PageWidth';
import SEO from '../components/atoms/SEO/SEO';
import SmartLink from '../components/atoms/SmartLink/SmartLink';
import Status from '../components/atoms/Status/Status';

const Freelance = () => (
  <Layout>
    <SEO title="Front-End Engineer & Designer" />
    <PageWidth isContent={true}>
      <h1>Software Architect & Engineer</h1>
      <p>
        I have around 25 years of experience as software architect, engineer and designer
        with technologies like React, React Native, Node.js, HTML5, CSS3, JavaScript and so on...
      </p>
      <p>
        <Status
          // text="I am available."
          // text="Currently I am fully booked."
          text="Looking for new projects from August 1, 2020."
          // text="Although extension is likely."
        />
      </p>
      {/* <p>
        Find all relevant information for placement in my latest
        <SmartLink
          to="http://profile.ludwigweise.de"
          inNewWindow={true}
          punctuation=","
        >
          freelance profile
        </SmartLink>
        <SmartLink to="http://portfolio.ludwigweise.de" inNewWindow={true}>
          portfolio
        </SmartLink>
        or on my
        <SmartLink
          to="https://www.xing.com/profile/KarlLudwig_Weise"
          inNewWindow={true}
          punctuation="."
        >
          XING profile
        </SmartLink>
      </p> */}
      <p>
        Inspired by
        <SmartLink
          to="https://www.onepercentfortheplanet.org"
          inNewWindow={true}
        >
          One Percent for the planet
        </SmartLink>
        I am giving back 1% of my net income to the environment.
      </p>
    </PageWidth>
  </Layout>
);

export default Freelance;
