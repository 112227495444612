import React from "react";

interface Props {
  text: string;
}

const Status = (props: Props) => {
  return <strong>{props.text}</strong>;
};

export default Status;
